import 'normalize.css';
import './index.scss';

import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import NavContext, { NavProvider } from '../../components/Layout/Nav/NavProvider';

import { getCurrentLanguage } from "../../i18n";
import SectionHeader from '../../components/SectionHeader';
import LegalSection from '../../components/LegalSection';
import DataPanel from '../../components/DataPanel';
import DataPanelItem from '../../components/DataPanel/DataPanelItem';
import LegalPage from '../../components/LegalPage';
import LegalAcceptCookies from '../../components/LegalAcceptCookies';
import { t } from 'i18next';

const PageTemplate: FC = () => {
  const page: any = {}
  page.i18n_lang = getCurrentLanguage();

  const { isMobile } = useContext(NavContext);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);
  const section = useRef();
  const slug = typeof window !== 'undefined' ? window.location.pathname : '';

  let sectionIndex = 1;

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  return <NavProvider>
    <Layout
      language={page.i18n_lang}
      isHome={false}
      isCookiesHidden
    >
      <SEO
        title={t('legal.pageTitle')}
        description={t('legal.pageDescription')}
        lang={page.i18n_lang}
        slug={slug}
      />
      <SectionHeader h1={t('contact.form.policy.conditionsLink')} inView={true } isTop />

      <LegalPage>
        <LegalSection title={t('legal.1.title')} index={sectionIndex ++}>
          <p ref={section}>
            {t('legal.1.description')}
          </p>

          <div className="legal__table">
            <DataPanel>
              <DataPanelItem title={t('legal.1.holder')} alignedToRight>
                YOUPLANET, S.L. ("YOUPLANET")
              </DataPanelItem>
              <DataPanelItem title={t('legal.1.nif')} alignedToRight>
                B-95.815.783
              </DataPanelItem>
              <DataPanelItem title={t('legal.1.address')} alignedToRight>
                CALLE ÁVILA 41, 08005 <br />BARCELONA
              </DataPanelItem>
              <DataPanelItem title={t('legal.1.email')} alignedToRight>
                INFO@YOUPLANET.ES 
              </DataPanelItem>
              <DataPanelItem title={t('legal.1.web')} alignedToRight>
                HTTPS://YOUPLANET.COM/ 
              </DataPanelItem>
            </DataPanel>
          </div>

          {Object.values(t('legal.1.conditions', { returnObjects: true })).map((element) => (
            <p>{element}</p>
          ))}

        </LegalSection>

        <LegalSection title={t('legal.2.title')} index={sectionIndex ++}>
          <p>
            {t('legal.2.description')}
          </p>
        </LegalSection>
        
        <LegalSection title={t('legal.3.title')} index={sectionIndex ++}>
          {Object.values(t('legal.3.conditions', { returnObjects: true })).map((element) => (
            <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.4.title')} index={sectionIndex ++}>
          <ol>
            <li>
              <span className="list-separator"></span>
              <div>
                {Object.values(t('legal.4.conditions.a', { returnObjects: true })).map((element) => (
                  <p>{element}</p>
                ))}
              </div>
            </li>
            <li>
              <span className="list-separator"></span>
              <div>
                <p>
                  {t('legal.4.conditions.b')}
                </p>
              </div>
            </li>
            <li>
              <span className="list-separator"></span>
              <div>
                <p>
                {t('legal.4.conditions.c')}
                </p>
              </div>
            </li>
            <li>
              <span className="list-separator"></span>
              <div>
                <p>
                {t('legal.4.conditions.d')}
                </p>
              </div>
            </li>
            <li>
              <span className="list-separator"></span>
              <div>
                <p>{t('legal.4.conditions.e.title')}</p>
                <ol>
                {Object.values(t('legal.4.conditions.e.paragraphs', { returnObjects: true })).map((element) => (
                  <li>
                    <span className="list-separator"></span>
                    <div>
                      <p>{element}</p>
                    </div>
                  </li>
                ))}
                </ol>
              </div>
            </li>
          </ol>
        </LegalSection>
        <LegalSection title={t('legal.5.title')} index={sectionIndex ++}>

          {Object.values(t('legal.5.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>
        <LegalSection title={t('legal.6.title')} index={sectionIndex ++}>
          {Object.values(t('legal.6.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.7.title')} index={sectionIndex ++}>
          {Object.values(t('legal.7.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.8.title')} index={sectionIndex ++}>
          {Object.values(t('legal.8.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.9.title')} index={sectionIndex ++}>
          {Object.values(t('legal.9.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.10.title')} index={sectionIndex ++}>
          {Object.values(t('legal.10.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.11.title')} index={sectionIndex ++}>
          {Object.values(t('legal.11.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.12.title')} index={sectionIndex ++}>
          {Object.values(t('legal.12.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.13.title')} index={sectionIndex ++}>
          {Object.values(t('legal.13.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.14.title')} index={sectionIndex ++}>
          {Object.values(t('legal.14.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}
        </LegalSection>

        <LegalSection title={t('legal.15.title')} index={sectionIndex ++}>
          {Object.values(t('legal.15.paragraphs', { returnObjects: true })).map((element) => (
                <p>{element}</p>
          ))}

          <div className="legal__table">
            <DataPanel>
              <DataPanelItem title={t('legal.15.list.company')} alignedToRight>
                YOUPLANET, S.L.
              </DataPanelItem>
              <DataPanelItem title={t('legal.15.list.address')} alignedToRight>
                CALLE ÁVILA 41, 08005 <br />BARCELONA
              </DataPanelItem>
              <DataPanelItem title={t('legal.15.list.contact')} alignedToRight>
                INFO@YOUPLANET.ES 
              </DataPanelItem>
            </DataPanel>
          </div>
          <p>
            <LegalAcceptCookies />
          </p>
        </LegalSection>
        
        <p className="legal__last-check-date">{new Date(2022, 6).toLocaleString(page.i18n_lang, { month: "long", year: "numeric" }) }</p>
      </LegalPage>
    </Layout>
  </NavProvider>
}

export default PageTemplate 
