import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import NavContext from "../Layout/Nav/NavProvider";
import { Checkbox } from "../Checkbox";
import { cookieName } from '../Layout/Cookies/Cookies';
import { t } from 'i18next';

const LegalAcceptCookies: FC = () => {
  const { setConsentCookies } = useContext(NavContext);
  const [isActive, setIsActive] = useState(false);

  const handleCookies = useCallback(() => {
    setConsentCookies(!isActive);
    setIsActive(!isActive);
    }, [isActive]);

  function initConsent() {
    const cookie = document.cookie.includes(`${cookieName}=1`);

    if (cookie) {
      setIsActive(true);
    }
  }

  useEffect(() => { 
    initConsent();
  }, [] )

  return (
    <label htmlFor="legal" onChange={() => handleCookies()}>
      <Checkbox isDark name="legal" isChecked={isActive} />
      {t('legal.acceptTerms')}
    </label>
  );
};

export default LegalAcceptCookies;
